
const initialState = {
  modules: []
};

const refreshModules = (state = initialState, action) =>  {
  const newState = {...state};
  if(action.type === 'REFRESH_MODULES'){
    // console.log("=====> setting modules: ", action.modules)
    newState.modules = action.modules || []
  }

  return newState;
};

export default refreshModules;