import constants from 'src/utils/constants'

export const LOCALE_EN = {
    "home": "Home",
    "routes": {
      "home": "Home",
      "dashboard": "Dashboard",
      "login": "Login",
      "administrators": "Administrators",
      "customers": "Creators",
      "newCustomer": "New Creator",
      "editCustomer": "Edit Creator",
      "commons": "Commons",
      "profile": "Profile",
      "users": "Creators&Collectors",
      "collections": "Collections",
      "category_nft": "Category NFT",
      "network_nft": "Network NFT",
      "catalogs": "Catalogs"
    },
    "profilenav": {
      "account": {
        "title": "Account",
        "profile": "Profile",
        "logout": "Logout"
      }
    },
    "views": {
      "login": {
        "title": "Login",
        "description": constants.app_description,
        "messages": {
          "success_login": "Invalid username or invalid password"
        }
      },
      "administrators": {
        "title": "Administrators",
        "table": {
          "name": "Name",
          "username": "Username",
          "actions": "Acciones",
        },
        "messages": {
          "success_delete": "El administrador ha sido eliminado"
        }
      },
      "administrator_form": {
          "title_1": "New Administrator",
          "title_2": "Edit Administrator",
          "buttons": {
            "save": "Save",
            "cancel": "Cancel",
          },
          "message": {
            "success_create": "The administrator has been created",
            "success_update": "The administrator has been updated"
          },
          "fields": {
              "first_name": {
                "name": "First Name",
                "placeholder": "Joe"
              },
              "last_name": {
                "name": "Last Name",
                "placeholder": "Dee"
              },
              "username": {
                "name": "Username",
                "placeholder": "joe_dee"
              },
              "email": {
                "name": "Email",
                "placeholder": "joe.dee@email.com"
              },
              "password": {
                "name": "Password",
                "placeholder": "******",
                "tooltip": "If you aren't want to change the password, ommit this field",
                "error" : "Password length must be atleast 8 characters, one uppercase, one lowercase, one number and one special character."
              },
          }
      },
      "customer": {
        "title": "Creators",
        "title_2": "Edit Creator",
        "title_3": "New Creator",
        "table": {
          "name": "Name",
          "wallet_address": "Wallet Address",
          "email": "Email",
          "package": "Package",
          "status": "Status",
          "actions": "Actions",
          "filters": {
            "status_options": {
              "enabled": "Enabled",
              "disabled": "Disabled",
              "pending": "Prospect",
              "rejected": "Rejected"
            }
          }
        },
        "messages": {
          "create_success": "The customer has been created",
          "update_success": "The customer has been updated",
          "delete_success": "The customer has been deleted",
          "approve_success": "The customer has been approved",
          "reject_success": "The customer has been rejected",
          "confirm_delete": "¿Are you sure to delete the customer \"{{name}}\"?",
          "success_delete": "The customer has been deleted",
          "confirm_disable": "¿Are you sure to disable the customer \"{{name}}\"?",
          "confirm_enable": "¿Are you sure to enable the customer \"{{name}}\"?",
          "disable_success": "The customer has been disabled",
          "enable_success": "The customer has been enabled"
        },
        "errors": {
          "get_packages_list": "An error ocurred while getting package list",
          "get_customer_to_edit": "An error ocurred while getting customer data to edit",
          "create_failed": "The customer creation process has failed",
          "update_failed": "The customer update process has failed",
          "delete_failed": "The customer delete process has failed",
          "approve_failed": "The customer approve process has failed",
          "reject_failed": "The customer rejection process has failed",
          "enable_failed": "The customer enable process has failed",
          "disable_failed": "The customer disable process has failed"
        },
        "form": {
          "fields": {
            "first_name": {
              "name": "First name",
              "placeholder": "Jhon"
            },
            "last_name": {
              "name": "Last name",
              "placeholder": "Doe"
            },
            "email": {
              "name": "Email",
              "placeholder": "jhondoe@example.com",
            },
            "package_id": {
              "name": "Package",
              "placeholder": "Select a Package",
            },
            "wallet_address": {
              "name": "Wallet address",
              "placeholder": "Wallet address",
            },
            "wallet_name": {
              "name": "Wallet name",
              "placeholder": "Wallet name",
            },
            "phone": {
              "name": "Phone",
              "placeholder": "+00 0000000000",
            },
            "status_feedback_validation": {
              "name": "Feedback",
              "placeholder": "Feedback to include in the email message",
            },
          }
        },
        "form_pending_validation": {
          "title": "Approve or Reject New External Customer",
          "text_1": "You can fill in additional information if available",
          "text_2": "Approval Report",
          "text_3": "Approve and Enable",
          "text_4": "Reject",
          "text_5": "Decide which opinion to save for this customer"
        }
      },
      "user": {
        "title": "All Users",
        "table": {
          "name": "Name",
          "wallet_address": "Wallet Address",
          "email": "Email",
          "package": "Package",
          "status": "Status",
          "actions": "Actions",
          "filters": {
            "status_options": {
              "enabled": "Enabled",
              "disabled": "Disabled",
              "pending": "Prospect",
              "rejected": "Rejected"
            }
          }
        }
      },
      "collection": {
        "title": "Collections",
        "table": {
          "name": "Name",
          "customer": "Customer Address",
          "slug": "Slug",
          "oficial_link": "Link",
          "enabled": "Enabled",
          "actions": "Actions"
        },
        "messages": {
          "confirm_disable": "¿Are you sure to disable the collection \"{{name}}\"?",
          "confirm_enable": "¿Are you sure to enable the collection \"{{name}}\"?",
          "disable_success": "The collection has been disabled",
          "enable_success": "The collection has been enabled"
        },
        "errors": {
          "enable_failed": "The collection enable process has failed",
          "disable_failed": "The collection disable process has failed"
        },
      },
      "category_nft": {
        "title": "Categories NFT",
        "table": {
          "name": "Name",
          "actions": "Acciones",
        },
        "messages": {
          "success_delete": "The category has been delete"
        }
      },
      "category_nft_form": {
          "title_1": "New Category NFT",
          "title_2": "Edit Category NFT",
          "message": {
            "success_create": "The category NFT has been created",
            "success_update": "The category NFT has been updated"
          },
          "fields": {
              "name": {
                "name": "Name",
                "placeholder": "Art"
              },
          }
      },
      "network_nft": {
        "title": "Networks NFT",
        "table": {
          "name": "Name",
          "actions": "Acciones",
        },
        "messages": {
          "success_delete": "The network has been delete"
        }
      },
      "network_nft_form": {
        "title_1": "New Network NFT",
        "title_2": "Edit Network NFT",
        "message": {
          "success_create": "The network NFT has been created",
          "success_update": "The network NFT has been updated"
        },
        "fields": {
          "name": {
            "name": "Name",
            "placeholder": "Etherem (ETH)"
          },
        }
      },
    },
    "common": {
      "search_filter": {
        "buttons": {
          "search": "Search",
          "reset": "Clean"
        },
        "placeholder": "Search"
      },
      "buttons": {
        "save": "Save",
        "edit": "Edit",
        "delete": "Delete",
        "activate": "Activate",
        "cancel": "Cancel",
        "add": "Add",
        "new": "New",
        "refresh": "Refresh",
        "update": "Update",
        "single": "View details",
        "back": "Back",
        "export": "Export",
        "report": "Report",
        "skip": "Skip",
        "approve": "Approve",
        "reject": "Reject",
        "confirm": "Confirm",
        "disable": "Disable",
        "enable": "Enable",
        "export_csv": "Export CSV"
      },
      "tooltips": {
        "edit": "Edit",
        "delete": "Delete",
      },
      "status": {
        "active": "Active",
        "inactive": "Inactive",
        "pending": "Pending",
        "disabled": "Disabled",
        "rejected": "Rejected",
        "enabled": "Enabled"
      },
      "table": {
        "name": "Name",
        "type": "Type",
        "created_at": "Created at",
        "description": "Description",
        "actions": "Actions",
        "start_date": "Start date",
        "end_date": "End date"
      },
      "modal_confirm_delete": {
        "title": "Are you sure to delete the item {{element}}?",
        "confirm": "Confirm",
        "cancel": "Cancel"
      },
      "messages": {
        "prompt_block_navigation": "¿Are you sure to leave this page?"
      }
    }
  }