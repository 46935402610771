import i18n from 'src/locale/i18n'

const routesMap = {
  home: {
    path: '/home',
    name: i18n.t('routes.home'),
  },
  dashboard: {
    path: '/dashboard',
    name: i18n.t('routes.dashboard'),
  },
  login: {
    path: '/login',
    name: i18n.t('routes.login'),
  },
  administrators: {
    path: '/administrators',
    name: i18n.t('routes.administrators'),
  },
  customers: {
    path: '/customers',
    name: i18n.t('routes.customers'),
  },
  newCustomer: {
    path: '/customers/new',
    name: i18n.t('routes.newCustomer'),
  },
  editCustomer: {
    path: '/customers/edit/:id',
    name: i18n.t('routes.editCustomer'),
  },

  profile: {
    path: '/profile',
    name: i18n.t('routes.profile')
  },
  users: {
    path: '/users',
    name: i18n.t('routes.users'),
  },
  collections: {
    path: '/collections',
    name: i18n.t('routes.collections'),
  },
  category_nft: {
    path: '/category-nft',
    name: i18n.t('routes.category_nft'),
  },
  network_nft: {
    path: '/network-nft',
    name: i18n.t('routes.network_nft'),
  },
}


export default routesMap