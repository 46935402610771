import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    /*INICIO - ESTILOS PARA EL CARD*/
        .ant-card {
            // border-radius: 5px;
        }
        
        .ant-card-head{
            // // border-radius: 5px 5px 0px 0px;
            background-color : #DADADA;
            // border-bottom: 1px solid #919090;
        }

        .ant-card-bordered {
            // border: 1px solid #919090;
        }

        .ant-card-head-title{
            // font-size : 1.5rem;
        }
    /*FIN - ESTILOS PARA EL CARD*/
`;
