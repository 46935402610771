
const initialState = { 
    first_name: '',
    last_name : '',
    image     : '',
};

const changeProfile = (state = initialState, action) =>  {
    const newState = {...state};
    if (action.type === 'UPDATED_PROFILE') {
        newState.first_name = action.first_name
        newState.last_name  = action.last_name
        newState.image      = action.image
    }

    return newState;
};

export default changeProfile;