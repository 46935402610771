import React, { Component } from 'react'
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { Styles } from "./styles/index";
import {message} from 'antd'
import routesMap from 'src/routesMap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

message.config({duration: 5});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

class App extends Component {
  render() {
    return (
      <>
        {
          process.env.NODE_ENV === 'development' ? 
            <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path={ routesMap.login.path } name={ routesMap.login.name } render={(props) => <Login {...props} />} />
                  <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                  <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                </Switch>
              </React.Suspense>
            </HashRouter>
          :
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path={ routesMap.login.path } name={ routesMap.login.name } render={(props) => <Login {...props} />} />
                  <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                  <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
        }
      </>
    )
  }
}

export default App
