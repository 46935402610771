import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { LOCALE_ES } from "./es";
import { LOCALE_EN } from "./en";
 
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: LOCALE_EN
      },
      es: {
        translation: LOCALE_ES
      }
    },
    fallbackLng: "en",
});
 
i18n.changeLanguage("en");

export default i18n